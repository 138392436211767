<template>
  <div>
    <button @click="sendMessage">发送消息</button>
    <ul>
      <li v-for="(value, index) in messageList" :key="index">{{ value }}</li>
    </ul>
  </div>
</template>

<script>
import 'signalr'
export default {
  data() {
    return {
      proxy: null,
      messageList: [],
    }
  },
  created() {
    this.initWs()
  },
  methods: {
    initWs() {
      //let hubUrl = process.env.VUE_APP_API_URL + '/chatHub'
      let hubUrl = "https://oa.hopebeauty.com"+ '/chatHub'
      let hub = $.hubConnection(hubUrl)

      hub.qs = { mac: 'as:sd:fs:12' }

      this.proxy = hub.createHubProxy('mailHub')

      // 异常信息回调
      this.proxy.on('sendMessageCallBack', (msg) => {
        this.messageList.push(msg)
      })

      this.proxy.on('notice', () => {
        this.$message.info('当前主机已经被关机')
      })

      hub
        .start()
        .done((connection) => {
          this.proxy.invoke('login', '张三', 2732)
        })
        .fail((error) => {
          this.$message.error('在线聊天功能,连接失败,请刷新页面进行重新尝试')
        })
    },
    sendMessage() {
      this.proxy.invoke('sendMessage', '客户端消息')
    },
  },
}
</script>

<style lang="scss" scoped></style>
